<!-- 关于我们/企业简介-->
<template>
  <div class="solution">
    <div class="banner pc-banner">
      <img class="img1" :src="bannerUrl1" alt="" />
      <img class="img2" :src="bannerUrl2" alt="" />
    </div>
    <div class="pain_point">
      <div class="pain_point_box">
        <div class="pain_point_box_item">
          <div class="img">
            <img :src="pain_point1.img" alt="" />
          </div>
          <div class="text">
            <div class="title">
              {{ pain_point1.name }}
            </div>
            <div class="tip">{{ pain_point1.introduce }}</div>
          </div>
        </div>
        <div class="pain_point_box_item">
          <div class="img">
            <img :src="pain_point2.img" alt="" />
          </div>
          <div class="text">
            <div class="title">
              {{ pain_point2.name }}
            </div>
            <div class="tip">{{ pain_point2.introduce }}</div>
          </div>
        </div>
        <div class="pain_point_box_item">
          <div class="img">
            <img :src="pain_point3.img" alt="" />
          </div>
          <div class="text">
            <div class="title">
              {{ pain_point3.name }}
            </div>
            <div class="tip">{{ pain_point3.introduce }}</div>
          </div>
        </div>
        <div class="pain_point_box_item">
          <div class="img">
            <img :src="pain_point4.img" alt="" />
          </div>
          <div class="text">
            <div class="title">
              {{ pain_point4.name }}
            </div>
            <div class="tip">{{ pain_point4.introduce }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pc_tool" ref="retailStore1">
      <div class="tool_title">农资店数字化工具</div>
      <div class="tool_tab">
        <div
          @click="handleCheck(index)"
          class="tool_tab_item"
          v-for="(item, index) in tabs"
          :key="index"
          :class="index == tabKey ? 'active_tabs' : ''"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="tool_card" v-if="tabKey == 0">
        <div class="tool_card_left">
          <div class="title1">内卷，从信息化开始，打造企业碾压优势</div>
          <div class="title2">农资行业99%的企业，</div>
          <div class="title3">没有完成信息化</div>
        </div>
        <div class="tool_card_right">
          <div class="tool_card_right_text">
            <div class="icon">宝乡通功能一</div>
            <div class="title1">农资企业信息化底层系统</div>
            <ul>
              <li><span>✓</span>公共信息:监管要求、用药限制</li>
              <li><span>✓</span>企业信息:名称、地址、营业执照</li>
              <li><span>✓</span>产品信息:品牌、功效、组合、三证</li>
              <li><span>✓</span>营销信息:价格、渠道、推广、服务</li>
            </ul>
            <div class="title2" >
              信息的公开透明极大程度上,提高了经营发展的便利性和正确性<br>
              信息化是数字化的基础和前提，是农资人经营决策的依据。
            </div>
          </div>
          <div class="tool_card_right_img">
            <img
              src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20243226/17062327278670743619"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="tool_card" v-else-if="tabKey == 1">
        <div class="tool_card_left">
          <div class="title4">
            数字化=互联网的高效+进销存节点控制+数据录入自动化
          </div>
          <div class="title5">
            <p>专业的工作傻瓜化</p>
            <p>复杂的工作简单化</p>
            <p>多人协同的工作一人一键完成</p>
          </div>
        </div>
        <div class="tool_card_right">
          <div class="tool_card_right_text">
            <div class="icon">宝乡通功能二</div>
            <div class="title1">门店进销存收银数据自动录入工具</div>
            <div class="title3">
              <p>线下实体店、线上小程序店管理后台全同步</p>
              <p>收出货管理、收银记录、赊销记账一键搞定</p>
              <p>库存盘点、经营财务分析、后台数据一目了然</p>
            </div>
            <div class="title4">宝乡通帮助企业做到</div>
            <div class="title5">
              <p>• 专业人员的工作普通人能完成</p>
              <p>• 复杂的工作简单化</p>
              <p>• 多人的工作一人能完成</p>
            </div>
          </div>
          <div class="tool_card_right_img">
            <img
              src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20242926/17062361500272296598"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="tool_card" v-else-if="tabKey == 2">
        <div class="tool_card_left">
          <div class="title6">
            <p>台帐监管</p>
            <p>营销自控</p>
          </div>
          <div class="title7">
            <p>引流产品、利润产品、大路产品、</p>
            <p>拳头产品自动复盘。</p>
            <p>账做清楚了，经营就有数了，</p>
            <p>让台账不只是监管要求</p>
          </div>
        </div>
        <div class="tool_card_right">
          <div class="tool_card_right_text">
            <div class="icon">宝乡通功能三</div>
            <div class="title6">
              <p>台账监管/营销自控，</p>
              <p>自主上报、自动上报双通道</p>
            </div>
            <div class="title3">
              <p>系统设置自动上报、自主上报双通道</p>
              <p>已接入广东省农业农村厅数字监管平台</p>
            </div>
          </div>
          <div class="tool_card_right_img">
            <img
              src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20242926/17062361525543065930"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="tool_card" v-else-if="tabKey == 3">
        <div class="tool_card_left">
          <div class="title8">
            <p>小程序网店</p>
            <p>更多的产品铺货更便宜的进价</p>
          </div>
        </div>
        <div class="tool_card_right">
          <div class="tool_card_right_text">
            <div class="icon">宝乡通功能四</div>
            <div class="title1">小程序网店，本地智能营销软件</div>
            <ul>
              <li><span>✓</span>使用教程：打通铺货渠道，做好广告宣传</li>
              <li><span>✓</span>门店：优化产品供给，服务创造价值</li>
              <li><span>✓</span>农户：手机下单送到家，领劵优惠购</li>
            </ul>
            <div class="title2">
              从坐商零售变为行商批发预售，从你找客户到客户找你仓管员、送货员、业务员、收银员、四人工作，一人搞掂。
            </div>
          </div>
          <div class="tool_card_right_img">
            <img
              src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20242926/17062361550843928477"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mobile_tool" ref="retailStore2">
      <div class="tool_title">农资店数字化工具</div>
      <div class="tool_card">
        <div class="app_tool_card_right_text">
          <div class="tool_card_right_text1">
            <div class="tool_card_right_text">
              <div class="icon">宝乡通功能一</div>
              <div class="title1">农资企业信息化底层系统</div>
              <ul>
                <li><span>✓</span>公共信息:监管要求、用药限制</li>
                <li><span>✓</span>企业信息:名称、地址、营业执照</li>
                <li><span>✓</span>产品信息:品牌、功效、组合、三证</li>
                <li><span>✓</span>营销信息:价格、渠道、推广、服务</li>
              </ul>
            </div>
            <div class="tool_card_right_img">
              <img
                src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20243226/17062327278670743619"
                alt=""
              />
            </div>
          </div>
          <div class="title2" style="font-size: 12px;">
           信息的公开透明极大程度上提高了经营发展的便利性和正确性信息化是数字化的基础和前提，是农资人经营决策的依据
          </div>
        </div>
        <div class="tool_card_left">
          <div class="title1">内卷，从信息化开始，打造企业碾压优势</div>
          <div class="title2">农资行业99%的企业，</div>
          <div class="title3">没有完成信息化</div>
        </div>
      </div>
      <div class="tool_card">
        <div class="tool_card_right">
          <div class="tool_card_right_text">
            <div class="icon">宝乡通功能二</div>
            <div class="title1" style="white-space: nowrap;">门店进销存收银数据自动录入工具</div>
            <div class="title3 app_title3">
              <p>线下实体店、线上小程序店管理后台全同步</p>
              <p>收出货管理、收银记录、赊销记账一键搞定</p>
              <p>库存盘点、经营财务分析、后台数据一目了然</p>
            </div>
            <div class="title4" style="font-size: 13px;margin-bottom: 11px">宝乡通帮助企业做到</div>
            <div class="title5">
              <p>• 专业人员的工作普通人能完成</p>
              <p>• 复杂的工作简单化</p>
              <p>• 多人的工作一人能完成</p>
            </div>
          </div>
          <div class="tool_card_right_img app_imag">
            <img
              src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20242926/17062361500272296598"
              alt=""
            />
          </div>
        </div>
        <div class="tool_card_left">
          <div class="title4" style="white-space: nowrap;">
            数字化=互联网的高效+进销存节点控制+数据录入自动化
          </div>
          <div class="title5">
            <p>专业的工作傻瓜化</p>
            <p>复杂的工作简单化</p>
            <p>多人协同的工作一人一键完成</p>
          </div>
        </div>
      </div>
      <div class="tool_card">
        <div class="tool_card_right">
          <div class="tool_card_right_text">
            <div class="icon">宝乡通功能三</div>
            <div class="title6 app_title3">
              <p>台账监管/营销自控，</p>
              <p>自主上报、自动上报双通道</p>
            </div>
            <div class="title3">
              <p>系统设置自动上报、自主上报双通道</p>
              <p>已接入广东省农业农村厅数字监管平台</p>
            </div>
          </div>
          <div class="tool_card_right_img app_imag app_imag2">
            <img
              src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20242926/17062361525543065930"
              alt=""
            />
          </div>
        </div>
        <div class="tool_card_left">
          <div class="title6">
            <p>台帐监管</p>
            <p>营销自控</p>
          </div>
          <div class="title7">
            <p>引流产品、利润产品、大路产品、拳头产品自动复盘。</p>
            <p>账做清楚了，经营就有数了，让台账不只是监管要求</p>
          </div>
        </div>
      </div>
      <div class="tool_card">
        <div class="app_tool_card_right_text">
          <div class="tool_card_right_text1">
            <div class="tool_card_right_text">
              <div class="icon">宝乡通功能四</div>
              <div class="title1" style="white-space: nowrap;">小程序网店，本地智能营销软件</div>
              <ul>
                <li><span>✓</span>使用教程：打通铺货渠道，做好广告宣传</li>
                <li><span>✓</span>门店：优化产品供给，服务创造价值</li>
                <li><span>✓</span>农户：手机下单送到家，领劵优惠购</li>
              </ul>
            </div>
            <div class="tool_card_right_img app_imag app_imag3">
              <img
                src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20242926/17062361550843928477"
                alt=""
              />
            </div>
          </div>
          <div class="title2 title7" style="font-size: 12px;">
            <p style="margin-bottom: 2px;">从坐商零售变为行商批发预售，从你找客户到客户找你</p>
            <p>仓管员、送货员、业务员、收银员、四人工作，一人搞掂</p>
          </div>
        </div>
        <div class="tool_card_left">
          <div class="title8">
            <p>小程序网店</p>
            <p>更多的产品铺货更便宜的进价</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用实例 -->
    <div class="retail_box">
      <div class="retail">
        <div class="retail_card">
          <div class="left">
            <p style="color: #f7ea7a">简单易用</p>
            <p>老人小孩都能</p>
            <p>轻松驾驭</p>
          </div>
          <div class="center">+</div>
          <div class="right">
            <p>使用银行卡</p>
            <p>密码技术</p>
            <p style="color: #f7ea7a">防控信息泄露</p>
          </div>
        </div>
        <!-- 宝乡通® 功能讲解 -->
        <div class="title" style="display: none;">
          <div class="line"></div>
          <!-- <div class="text">宝乡通® 功能讲解</div> -->
          <div class="text">
            <span class="titile_text">宝乡通
            <span class="titile_icon">®</span>
          </span>
            功能讲解</div>
          <div class="line"></div>
        </div>
        <div class="swiper" style="display: none;">
          <swiper ref="mySwiper" :key="updateKey" :options="swiperOption">
            <swiper-slide
              class="partner-list"
              v-for="(item, index) in UseVideosList"
              :key="index"
            >
              <div class="video">
                <video :src="item.video"></video>
                <div class="video_text">
                  <div class="video_title">{{ item.title }} </div>
                  <div>{{ item.create_time }}</div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </div>
        <div class="title app_title">
          <div class="line"></div>
          <div class="text">
            <span class="titile_text">宝乡通
              <span class="titile_icon">®</span>
            </span>
            应用实例</div>
          <div class="line"></div>
        </div>
        <div class="big_video" ref="distributor">
          <video
            ref="myVideo"
            controls
            autoplay
            src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20240126/1706254565160387472"
          ></video>
        </div>
        <div class="title app_title">
          <div class="line"></div>
          <div class="text ">
            <span class="titile_text">宝乡通
              <span class="titile_icon">®</span>
            </span>
            使用教程</div>
          <div class="line"></div>
        </div>
        <div class="pc_mode_video" ref="manufacturer1">
          <div class="content gn-box-border" style="padding-left: 17px">
            <a-row>
              <a-col
                :span="8"
                style="margin-bottom: 32px"
                v-for="(item, index) in videoList"
                :key="index"
                @click="goDetail(item)"
              >
                <a-card
                  hoverable
                  :bordered="false"
                  class="card"
                  style="width: 442px; background: #f8f8f8"
                >
                  <div class="gn-full gn-rela" style="height: 283px">
                    <img class="gn-full" :src="item.img" />
                    <img
                      src="@/assets/images/newbazaarIntroduce/video.png"
                      class="gn-center"
                      style="width: 100px; height: 100px"
                    />
                  </div>
                  <a-card-meta :title="item.name" style="padding: 25px 18px ; font-weight: 700;">
                    <template slot="description">
                      <span class="time">{{ item.create_time }}</span>
                    </template>
                  </a-card-meta>
                </a-card>
              </a-col>
            </a-row>
          </div>
          <!-- <load-more :status="loadStatus" @load="loadMore"></load-more> -->
        </div>
        <div class="mobile_mode_video" ref="manufacturer2">
          <div class="content">
            <ul>
              <li
                v-for="(item, index) in videoList"
                :key="index"
                @click="goMobileDetail(item)"
              >
                <div class="video rela">
                  <img :src="item.img" class="gn-full" />
                  <div class="icon center">
                    <img src="@/assets/images/newbazaarIntroduce/video.png" />
                  </div>
                  <div class="bg_img"></div>
                </div>
                <div class="info">
                  <div class="title">{{ item.name }}</div>
                  <div class="time">{{ item.create_time }}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
         <!-- 查看更多 -->
         <div class="ViewMore">
           <div class="ViewMore-Noview" v-if="isShowMore">没有更多了</div>
           <div class="ViewMore-View" v-else @click="lookMore">查看更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPublishListByType } from '@/api/officialWebsite'
import { getArticleList } from '@/api/admin'

const mobileRetailList = [
  {
    num: 1,
    title: '提供全国优质厂，上万热销单品，找货快，可比价；'
  },
  {
    num: 2,
    title: '使用教程促销直达门店，拼团淡储低价进货；'
  },
  {
    num: 3,
    title: '快捷采购功能，减少重复工作量；'
  },
  {
    num: 4,
    title: '利用店长零售系统直接推广商品给农民；'
  },
  {
    num: 5,
    title: '农民线上购买下单后，可直接到店提货；'
  },
  {
    num: 6,
    title: '能帮助店家实现客户管理，增加客户粘性；'
  },
  {
    num: 7,
    title: '佣金设计，实现快速裂变传播；'
  },
  {
    num: 8,
    title: '利用平台提供的模板定制生成属于自己产品的海报；'
  },
  {
    num: 9,
    title: '保存图片或直接分享链接，即可在微信等社交媒体传播；'
  },
  {
    num: 10,
    title: '借助微信等社交工具将产品推广给更多的农户；'
  },
  {
    num: 11,
    title: '利用海报功能的多级分润，助力分裂传播；'
  }
]

export default {
  name: 'solution',
  data () {
    return {
      tabKey: '0',
      tabs: [
        {
          title: '企业信息化底层系统'
        },
        {
          title: '进销存管理+数据采集工具'
        },
        {
          title: '台账监管营销自控双通道'
        },
        {
          title: '小程序网店'
        }
      ],
      bannerUrl1: '',
      bannerUrl2: '',
      pain_point1: {},
      pain_point2: {},
      pain_point3: {},
      pain_point4: {},
      updateKey: +new Date(),
      swiperOption: {
        observer: true,
        slidesPerView: 3,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 1000000, // 5秒切换一次
          // 用户操作swiper以后，是否禁止autoplay.默认为true中止
          disableOnInteraction: false
        },
        // 设置轮播可循环
        loop: true
      },
      videoArrList: [], // 使用教程的所有视频
      videoPage: 1,
      videoPageSize: 6,
      isShowMore: false,
      videoList: [], // 使用教程
      UseVideosList: [], // 功能讲解
      reqParams: {
        page: 1,
        per_page: 9,
        search_data: '', // 关键字搜索
        advisory_type: '3', // 类型 1 公司新闻 2 行业动态 3 视频展示
        status: '', // 0 正常 1 停用 2 删除
        start_time: '', //  开始时间
        end_time: '', // 结束时间
        is_platform: '1'
      },
      loadStatus: 'loadmore',
      applicationExample: {}
    }
  },
  created () {
    this.handleMobileData()
    this.getData()
    this.getList()
  },
  mounted () {
    this.initData()
    this.watchRouteName()
    const type = this.$route.query.type || ''
    if (type) {
      if (type === 'retailStore') {
        setTimeout(() => {
          this.mappingPlatformType(1)
        }, 20)
      } else if (type === 'distributor') {
        setTimeout(() => {
          this.mappingPlatformType(2)
        }, 20)
      } else if (type === 'manufacturer') {
        setTimeout(() => {
          this.mappingPlatformType(3)
        }, 20)
      }
    }
    this.resetBannerNum()
    window.addEventListener('resize', this.resizePage)
  },
  beforeDestroy () {
    this.$refs.myVideo.pause()
    window.removeEventListener('resize', this.resizePage)
  },
  watch: {
    // 监听,当路由发生变化的时候执行
    $route: {
      handler: function (val, oldVal) {
        const type = val.query.type ? val.query.type : val.name
        if (type) {
          if (type === 'retailStore') {
            setTimeout(() => {
              this.mappingPlatformType(1)
            }, 20)
          } else if (type === 'distributor') {
            setTimeout(() => {
              this.mappingPlatformType(2)
            }, 20)
          } else if (type === 'manufacturer') {
            setTimeout(() => {
              this.mappingPlatformType(3)
            }, 20)
          }
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    resizePage () {
      this.resetBannerNum()
    },
    // 重置轮播图一次性的显示数量
    resetBannerNum () {
      if (window.document.body.clientWidth >= 992) {
        this.swiperOption.slidesPerView = 3
      } else if (window.document.body.clientWidth >= 768) {
        this.swiperOption.slidesPerView = 2
      } else if (window.document.body.clientWidth >= 576) {
        this.swiperOption.slidesPerView = 1
      } else {
        this.swiperOption.slidesPerView = 1
      }
      this.updateKey = +new Date()
    },
    async getData () {
      const res = await getPublishListByType({
        publish_type: ['9', '19', '20', '21']
      })
      const arr = res['9']
      const arr1 = res['20']
      this.applicationExample = arr1[0]
      arr.forEach((item) => {
        if (item.name === 'banner图') {
          this.bannerUrl2 = item.img
        }
        if (item.name === 'banner图移动端') {
          this.bannerUrl1 = item.img
        }
        if (item.code === '17061731992992666250') {
          this.pain_point1 = item
        }
        if (item.code === '17061732178263452495') {
          this.pain_point2 = item
        }
        if (item.code === '17061732349221865341') {
          this.pain_point3 = item
        }
        if (item.code === '17061732496025832726') {
          this.pain_point4 = item
        }
      })
      // 使用教程的 视频
      this.videoArrList = res['21']
      this.videoList = this.videoArrList.slice(0, this.videoPageSize)
      console.log('shuju', res)
    },
    watchRouteName () {
      this.$watch('$route.meta.title', (title) => {
        // 当前导航菜单切换滚动视图
        this.toggleNavMenuScrollView(title)
      })
    },
    toggleNavMenuScrollView (menuName) {
      console.log(menuName, 'ddasdsa')
      window.scrollTo(0, 0)
      setTimeout(() => {
        if (menuName === '功能介绍') {
          this.$refs.retailStore.scrollIntoView({ behavior: 'smooth' })
        } else if (menuName === '应用实例') {
          this.$refs.distributor.scrollIntoView({ behavior: 'smooth' })
        } else if (menuName === '使用教程') {
          this.$refs.manufacturer.scrollIntoView({ behavior: 'smooth' })
        }
      }, 30)
    },
    handleMobileData () {
      if (this.$isMobile) {
        this.retailList = mobileRetailList
      }
    },
    initData () {
      const { title } = this.$route.meta
      setTimeout(() => {
        // 从其它页面跳转过来滚动视图
        this.mappingMetaTitle(title)
      }, 100)
    },
    handleCheck (i) {
      this.tabKey = i
    },
    mappingPlatformType (i) {
      const fn = {
        1: () => this.scrollView('retailStore'), // 功能介绍
        2: () => this.scrollView('distributor'), // 应用实例
        3: () => this.scrollView('manufacturer') // 使用教程
      }[i]
      fn && fn()
    },
    mappingMetaTitle (title) {
      if (title) {
        const fn = {
          功能介绍: () => this.scrollView('retailStore'),
          应用实例: () => this.scrollView('distributor'),
          使用教程: () => this.scrollView('manufacturer')
        }[title]

        fn && fn()
      }
    },
    scrollView (tabRef) {
      window.scrollTo(0, 0)
      if (tabRef === 'retailStore' || tabRef === 'manufacturer') {
        setTimeout(() => {
          this.$refs[tabRef + '1'].scrollIntoView({ behavior: 'smooth' })
        }, 35)
        setTimeout(() => {
          this.$refs[tabRef + '2'].scrollIntoView({ behavior: 'smooth' })
        }, 35)
      } else {
        setTimeout(() => {
          this.$refs[tabRef].scrollIntoView({ behavior: 'smooth' })
        }, 35)
      }
    },
    async getList () {
      const { list, count } = await getArticleList(this.reqParams)

      if (this.reqParams.page == 1) {
        this.UseVideosList = list || []
      } else {
        this.UseVideosList = [...this.UseVideosList, ...list]
      }
      if (this.UseVideosList.length === 0) {
        this.loadStatus = 'empty'
      } else if (this.UseVideosList.length >= count) {
        this.loadStatus = 'nomore'
      } else {
        this.loadStatus = 'loadmore'
      }
      console.log(this.UseVideosList, 'smdx')
    },
    loadMore () {
      if (this.loadStatus === 'loading') return
      this.loadStatus = 'loading'
      this.reqParams.page += 1
      setTimeout(() => {
        this.getList()
      }, 500)
    },
    goDetail (item) {
      this.$router.push({
        name: 'video_bxt',
        query: {
          type: 3, // 1:公司新闻  2:行业动态  3:视频展示
          videoUrl: item.file,
          name: item.name,
          time: item.create_time
        }
      })
    },
    goMobileDetail (item) {
      this.$router.push({
        name: 'mobileVideoBxt',
        query: {
          type: 3, // 1:公司新闻  2:行业动态  3:视频展示
          videoUrl: item.file,
          name: item.name,
          time: item.create_time
        }
      })
    },
    // 查看更多
    lookMore () {
      const pageSize = 6
      this.videoPage += 1
      // 计算长度
      if (this.videoPageSize < this.videoArrList.length) {
        this.videoPageSize = this.videoPage * pageSize
        this.videoList = this.videoArrList.slice(0, this.videoPageSize)
      } else {
        this.isShowMore = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
// 隐藏移动端要显示的东西
.mobile-retail-item-ul {
  display: none !important;
}

.solution {
  margin-top: 65px;
  color: #333333;
  .banner {
    margin: 0 auto;
    width: 100%;
    background-size: cover;
    img {
      width: 100%;
      height: auto;
    }
    .img1 {
      display: none;
    }
    .img2 {
      display: block;
    }
  }
  .pain_point {
    padding: 87px 0px 0px;
    width: 100%;
    .pain_point_box {
      padding: 0px 50px 0px 100px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 1440px;
      .pain_point_box_item {
        margin-bottom: 101px;
        display: flex;
        align-items: center;
        width: 50%;
        img {
          margin-right: 38px;
          width: 60px;
          height: auto;
        }
        .text {
          .title {
            margin-bottom: 11px;
            font-size: 30px;
            font-weight: 700;
            color: #333333;
          }
          .tip {
            font-size: 20px;
          }
        }
      }
    }
  }
  .pc_tool {
    width: 100%;
    background: #eaeaea;
    .tool_title {
      padding: 115px 0px 87px;
      width: 100%;
      text-align: center;
      font-size: 60px;
      font-weight: 700;
      text-align: center;
      color: #333333;
    }
    .tool_tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-bottom: 1px solid #999999;
      .tool_tab_item {
        margin: 0px 40px;
        padding-bottom: 24px;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        cursor: pointer;
      }
    }
    .tool_card {
      width: 1440px;
      margin: 0 auto;
      margin-top: 82px;
      padding-bottom: 110px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tool_card_left {
        padding: 87px 37px 20px 28px;
        width: 421px;
        height: 509px;
        background: #333333;
        color: #ffffff;
        .title1 {
          margin-bottom: 132px;
          width: 280px;
          height: 69px;
          font-size: 28px;
        }
        .title2 {
          margin-bottom: 27px;
          font-size: 35px;
          font-weight: 700;
        }
        .title3 {
          font-size: 49px;
          font-weight: 700;
        }
        .title4 {
          margin-bottom: 127px;
          font-size: 24px;
          font-weight: 700;
        }
        .title5 {
          font-size: 24px;
          font-weight: 700;
          p {
            margin-bottom: 20px;
          }
        }
        .title6 {
          margin-top: 30px;
          margin-bottom: 50px;
          font-size: 48px;
          font-weight: 700;
        }
        .title7 {
          margin-bottom: 127px;
          font-size: 18px;
          font-weight: 700;
          p {
            margin-bottom: 10px;
          }
        }
        .title8 {
          margin-top: 30px;
          font-size: 48px;
          font-weight: 700;
          p:nth-child(1) {
            margin-bottom: 50px;
          }
          p:nth-child(2) {
            font-size: 40px;
          }
        }
      }
      .tool_card_right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 70px 37px 20px 76px;
        width: 1000px;
        height: 509px;
        background: #ffffff;
        .tool_card_right_text {
          height: 100%;
          flex: 1;
          color: #333333;
          .icon {
            margin-bottom: 20px;
            width: 180px;
            height: 30px;
            background: #00a0ac;
            font-size: 24px;
            font-weight: 400;
            text-align: center;
            line-height: 30px;
            color: #ffffff;
          }
          .title1 {
            font-size: 36px;
            font-weight: 700;
          }
          ul {
            margin-top: 52px;
            margin-bottom: 31px;
            li {
              margin-bottom: 16px;
              font-size: 18px;
              span {
                margin-right: 10px;
                color: #00a0ac;
                font-weight: 1000;
              }
            }
          }
          .title2 {
            font-size: 20px;
            text-align: left;
          }
          .title3 {
            margin: 23px 0px 20px;
            font-size: 20px;
            text-align: left;
            p {
              margin-bottom: 10px;
            }
          }
          .title4 {
            margin-bottom: 16px;
            font-size: 20px;
            font-weight: 700;
          }
          .title5 {
            font-size: 18px;
            font-weight: normal;
            text-align: left;
            p {
              margin-bottom: 5px;
            }
          }
          .title6 {
            margin: 50px 0px 30px;
            font-size: 36px;
            font-weight: 700;
            text-align: left;
          }
        }
        .tool_card_right_img {
          margin-left: 30px;
          width: 281px;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
  .mobile_tool {
    display: none;
  }
  // 应用实例
  .retail_box {
    padding: 94px 0px 110px;
    .retail {
      width: 1440px;
      margin: 0 auto;
      .dealer_plan {
        margin: 50px 0px 54px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        img {
          width: 50px;
          height: 49px;
        }
        span {
          padding-left: 15px;
          font-size: 30px;
          font-family: Source Han Sans CN, Source Han Sans CN-Bold;
          font-weight: 700;
          text-align: justifyLeft;
          color: #333333;
          line-height: 30px;
          box-sizing: border-box;
        }
      }
      .retail_card {
        padding-bottom: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        .left {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 306px;
          height: 238px;
          background-image: url("https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20243526/17062365544029368519");
          background-size: 100% 100%;
          p {
            font-size: 30px;
            font-family: Source Han Sans CN, Source Han Sans CN-Heavy;
            font-weight: 800;
            text-align: center;
            color: #ffffff;
            line-height: 48px;
          }
        }
        .center {
          margin: 0px 34px 20px 39px;
          font-size: 71px;
          font-family: Source Han Sans CN, Source Han Sans CN-Heavy;
          font-weight: 800;
          text-align: center;
          color: #0099d3;
        }
        .right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 306px;
          height: 238px;
          background-image: url("https://bcmarket.obs.cn-south-1.myhuaweicloud.com/20243526/17062365570925715165");
          background-size: 100% 100%;
          p {
            font-size: 30px;
            font-family: Source Han Sans CN, Source Han Sans CN-Heavy;
            font-weight: 800;
            text-align: center;
            color: #ffffff;
            line-height: 48px;
          }
        }
      }

      .title {
        margin: 0 auto;
        width: 1440px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 100px 0px 62px;
        box-sizing: border-box;
        .line {
          height: 1px;
          background-color: #dbdada;
          flex: 1;
        }
        .text {
          margin: 0px 12px;
          font-size: 34px;
          font-weight: 700;
          color: #333333;
          line-height: 24px;
          .titile_text{
            font-size: 34px;
            position: relative;
            padding: 0 10px;
            font-weight: 700;
            text-align: center;
          }
          .titile_icon {
            font-size: 20px;
            position: absolute;
            top: 0px;
            right: 2px;
          }
        }
      }

      .swiper {
        .partner-list {
          box-sizing: border-box;
          .video {
            width: 452px;
            video {
              width: 452px;
              height: 283px;
            }
            .video_text {
              width: 100%;
              height: 125px;
              background: #f8f8f8;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              padding: 0 24px;
              .video_title{
                font-weight: 700;
                font-size: 24px;
              }
            }
          }
        }

        .swiper-container {
          padding: 0px 30px;
          background-color: #fff;
          .swiper-button-prev,
          .swiper-button-next {
            color: #999999;
          }
          .swiper-button-prev {
            left: 0px;
            transform: translateY(-50%);
            color: #999999;
          }
          .swiper-button-next {
            right: 0px;
            transform: translateY(-50%);
            color: #999999;
          }
        }
      }
      .big_video {
        width: 1344px;
        height: 755px;
        margin:0 auto;
        video {
          width: 100%;
          height: 100%;
        }
      }
      .pc_mode_video {
        ::v-deep .ant-card-body {
          padding: 0px;
        }
      }
      .ViewMore{
        margin: 0 auto;
        .ViewMore-View{
          font-size: 18px;
          width: 174px;
          height: 43px;
          border: 1px solid #333333;
          margin: 0 auto;
          text-align: center;
          line-height: 43px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          cursor: pointer;
        }
        .ViewMore-Noview{
          cursor: pointer;
          font-size: 24px;
          color: #333333;
          margin-right: 5px;
          font-family: Source Han Sans CN, Source Han Sans CN-Regular;
          text-align: center;
        }
      }
      .mobile_mode_video {
        display: flex;
        display: none;
        justify-content: center;
        width: 100%;
        .content {
          width: 1440px;
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              margin-bottom: 50px;
              margin-right: 20px;
              width: 344px;
              height: 350px;
              cursor: pointer;
              .video {
                position: relative;
                width: 344px;
                height: 208px;
                .icon {
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  width: 39px;
                  height: 39px;
                  border-radius: 50%;
                  img {
                    width: 39px;
                    height: 39px;
                  }
                }
                .bg_img {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 344px;
                  height: 208px;
                  background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.3) 0%,
                    rgba(255, 255, 255, 0.3) 100%
                  );
                  border-radius: 5px 5px 0px 0px;
                }
              }
              .info {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 20px 26px 27px 26px;
                width: 100%;
                height: calc(350px - 208px);
                background-color: #f5f5f5;
                box-sizing: border-box;
                .title {
                  font-size: 24px;
                  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
                  font-weight: 400;
                  text-align: justifyLeft;
                  color: #333333;
                  line-height: 30px;
                }
                .time {
                  font-size: 16px;
                  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
                  font-weight: 400;
                  text-align: justifyLeft;
                  color: #333333;
                  line-height: 40px;
                }
              }
            }
            li:nth-child(4) {
              margin-right: 0px !important;
            }
            li:nth-child(8) {
              margin-right: 0px !important;
            }
          }
        }
      }
    }
  }
}
.active_tabs {
  font-weight: 700;
  border-bottom: 4px solid #00a0ac;
  color: #00a0ac;
}
::v-deep {
  .ant-card-meta-title {
    font-weight: 700;
    font-size: 24px;
  }
}
</style>

<style scoped lang='scss'>
// 手机端 样式
@import "./media";
</style>
